// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-article-1-js": () => import("./../../../src/pages/news/article-1.js" /* webpackChunkName: "component---src-pages-news-article-1-js" */),
  "component---src-pages-news-article-2-js": () => import("./../../../src/pages/news/article-2.js" /* webpackChunkName: "component---src-pages-news-article-2-js" */),
  "component---src-pages-news-article-3-js": () => import("./../../../src/pages/news/article-3.js" /* webpackChunkName: "component---src-pages-news-article-3-js" */),
  "component---src-pages-news-article-4-js": () => import("./../../../src/pages/news/article-4.js" /* webpackChunkName: "component---src-pages-news-article-4-js" */),
  "component---src-pages-news-article-5-js": () => import("./../../../src/pages/news/article-5.js" /* webpackChunkName: "component---src-pages-news-article-5-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

